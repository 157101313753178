<script>
	import { createEventDispatcher, onMount } from 'svelte'

	const dispatch = createEventDispatcher()

	const scale = 0.5
	const spacing = 10
	let canvas = null
	let context = null
	const v = []
	const V_X = (window.innerWidth * scale) / spacing
	const V_Y = (window.innerHeight * scale) / spacing

	onMount(() => {
		presetup()
		setup()
		animate()
	})

	function presetup() {
		canvas.width = window.innerWidth * scale
		canvas.height = window.innerHeight * scale
		context = canvas.getContext('2d')
		canvas.addEventListener('mousedown', handleMouseDown)
		canvas.style.transform = `scale(${1 / scale})`
	}

	function setup() {
		for (let x = 0; x < V_X + 1; x++) {
			v[x] = []
			for (let y = 0; y < V_Y + 1; y++) {
				v[x][y] = { x: 0, y: 0, m: 0 }
			}
		}
	}

	function animate() {
		requestAnimationFrame(tick)
	}

	function tick() {
		update()
		render()
		requestAnimationFrame(tick)
	}

	const xmatrix = [
		[0.5, 0.75, 0.5],
		[0.75, 0, 0.75],
		[0.5, 0.75, 0.5]
	]
	const xmatrix_sum = xmatrix.flat().reduce((s, v) => s + v, 0)
	const ymatrix = [
		[0.5, 0.75, 0.5],
		[0.75, 0, 0.75],
		[0.5, 0.75, 0.5]
	]
	const ymatrix_sum = ymatrix.flat().reduce((s, v) => s + v, 0)
	const mmatrix = [
		[0.5, 0.75, 0.5],
		[0.75, 1, 0.75],
		[0.5, 0.75, 0.5]
	]
	const mmatrix_sum = mmatrix.flat().reduce((s, v) => s + v, 0)

	function update() {
		const affection = 0.98
		for (let x = 1; x < V_X; x++) {
			for (let y = 1; y < V_Y; y++) {
				const sx =
					v[x - 1][y - 1].x * xmatrix[0][0] +
					v[x + 0][y - 1].x * xmatrix[0][1] +
					v[x + 1][y - 1].x * xmatrix[0][2] +
					v[x - 1][y + 0].x * xmatrix[1][0] +
					v[x + 0][y + 0].x * xmatrix[1][1] +
					v[x + 1][y + 0].x * xmatrix[1][2] +
					v[x - 1][y + 1].x * xmatrix[2][0] +
					v[x + 0][y + 1].x * xmatrix[2][1] +
					v[x + 1][y + 1].x * xmatrix[2][2]
				const sy =
					v[x - 1][y - 1].y * ymatrix[0][0] +
					v[x + 0][y - 1].y * ymatrix[0][1] +
					v[x + 1][y - 1].y * ymatrix[0][2] +
					v[x - 1][y + 0].y * ymatrix[1][0] +
					v[x + 0][y + 0].y * ymatrix[1][1] +
					v[x + 1][y + 0].y * ymatrix[1][2] +
					v[x - 1][y + 1].y * ymatrix[2][0] +
					v[x + 0][y + 1].y * ymatrix[2][1] +
					v[x + 1][y + 1].y * ymatrix[2][2]
				const sm =
					v[x - 1][y - 1].m * mmatrix[0][0] +
					v[x + 0][y - 1].m * mmatrix[0][1] +
					v[x + 1][y - 1].m * mmatrix[0][2] +
					v[x - 1][y + 0].m * mmatrix[1][0] +
					v[x + 0][y + 0].m * mmatrix[1][1] +
					v[x + 1][y + 0].m * mmatrix[1][2] +
					v[x - 1][y + 1].m * mmatrix[2][0] +
					v[x + 0][y + 1].m * mmatrix[2][1] +
					v[x + 1][y + 1].m * mmatrix[2][2]
				const p = v[x][y]
				p.x = (sx / xmatrix_sum) * affection
				p.y = (sy / ymatrix_sum) * affection
				p.m = sm / mmatrix_sum
			}
		}
	}

	function render() {
		context.clearRect(0, 0, canvas.width, canvas.height)
		for (let x = 0; x < V_X; x++) {
			for (let y = 0; y < V_Y; y++) {
				const p = v[x][y]
				const r = Math.atan2(p.y, p.x)
				context.save()
				context.translate(x * spacing - spacing, y * spacing - spacing)
				context.fillStyle = `hsl(${(r / Math.PI) * 180}deg, 100%, ${
					25 + 75 * (1 - p.m)
				}%)`
				// context.fillStyle = `hsl(${(r / Math.PI) * 180}deg, 100%, 50%)`
				context.fillRect(0, 0, spacing, spacing)
				// context.rotate(r)
				// context.fillStyle = 'black'
				// context.fillRect(0, 0, spacing, 1)
				// context.fillStyle = 'red'
				// context.fillRect(0, 0, 1, 1)
				context.restore()
			}
		}
	}

	function handleMouseDown() {
		dispatch('mousedown')
		document.addEventListener('mousemove', handleMouseMove)
		document.addEventListener('mouseup', handleMouseUp)
	}

	let px = null
	let py = null
	function handleMouseMove(event) {
		const x =
			Math.max(
				Math.min(Math.floor((event.clientX * scale) / spacing), V_X),
				0
			) + 1
		const y =
			Math.max(
				Math.min(Math.floor((event.clientY * scale) / spacing), V_Y),
				0
			) + 1
		if (px) {
			const dx = event.clientX - px
			const dy = event.clientY - py
			const a = Math.atan2(dy, dx)
			v[x][y].x = Math.cos(a)
			v[x][y].y = Math.sin(a)
			v[x][y].m = 1
		}
		px = event.clientX
		py = event.clientY
	}

	function handleMouseUp() {
		dispatch('mouseup')
		px = null
		py = null
		document.removeEventListener('mousemove', handleMouseMove)
		document.removeEventListener('mouseup', handleMouseUp)
	}
</script>

<style>
	canvas {
		z-index: -1;
		position: fixed;
		top: 0;
		left: 0;
		transform-origin: top left;
		transform: scale(10);
		filter: blur(10px);
	}
	p {
		margin-top: 1em;
	}
</style>

<p>Click and drag anywhere</p>
<canvas bind:this="{canvas}"></canvas>
