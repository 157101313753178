<script>
	import { onMount } from 'svelte'
	import Console from './Console.svelte'
	import Logotype from './Logotype.svelte'
	import Fluid from './Fluid.svelte'

	let mail = ''
	let preventSelection = false
	onMount(() => {
		setTimeout(() => {
			mail = 'moc.liamg@noredlac.lrac'.split('').reverse().join('')
		}, 10)
	})
</script>

<style>
	main {
		text-align: center;
		padding: 1em;
		max-width: 240px;
		margin: 0 auto;
	}

	h1 {
		text-transform: uppercase;
		font-size: 1.5em;
		line-height: 1.6em;
		font-weight: 600;
	}

	p {
		margin-top: 1em;
		margin-bottom: 1em;
	}

	.preventSelection {
		user-select: none;
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
</style>

<main class:preventSelection="{preventSelection}">
	<Console />
	<Logotype />
	<h1>Carl Calderon</h1>
	<p>I make stuff</p>
	<a href="{`mailto:${mail}`}">{mail}</a>
	<Fluid
		on:mousedown="{() => (preventSelection = true)}"
		on:mouseup="{() => (preventSelection = false)}"
	/>
</main>
