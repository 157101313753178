<script>
	import { quintInOut, elasticOut } from 'svelte/easing';
	import { fly, draw, scale } from 'svelte/transition';

	let drawLogo1 = false
	let drawLogo2 = false
	let fillLogo1 = false
	let fillLogo2 = false

	setTimeout(() => {
		drawLogo1 = true
		drawLogo2 = true
	}, 100)
	setTimeout(() => {
		fillLogo1 = true
		drawLogo1 = false
	}, 1000)
	setTimeout(() => {
		fillLogo2 = true
		drawLogo2 = false
	}, 2000)
</script>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	width="128px" height="128px" viewBox="0 0 32 32" enable-background="new 0 0 32 32" xml:space="preserve">
	{#if drawLogo1 || drawLogo2 || fillLogo1 || fillLogo2 }
	<circle in:scale={{duration: 1000, easing: elasticOut }} style="transform-origin: center center;" fill="#010101" cx="16" cy="16" r="16"/>
	{/if}
		{#if fillLogo1}
		<path fill="#FFFFFF" d="M17.615,17.657c-0.424,0.424-1.01,0.686-1.657,0.686c-1.293,0-2.342-1.048-2.342-2.342
		c0-1.294,1.049-2.343,2.342-2.343c0.647,0,1.233,0.262,1.657,0.686l2.816-2.816c-1.146-1.145-2.727-1.853-4.473-1.853
		c-3.493,0-6.325,2.832-6.325,6.325c0,3.494,2.832,6.325,6.325,6.325c1.747,0,3.328-0.708,4.473-1.853L17.615,17.657z"/>
		{/if}
		
		{#if fillLogo2}
		<path fill="#FFFFFF" d="M22.116,22.238c-1.597,1.596-3.802,2.584-6.238,2.584c-4.872,0-8.821-3.95-8.821-8.822
		c0-4.872,3.949-8.821,8.821-8.821c2.436,0,4.641,0.987,6.238,2.583l2.761-2.761c-2.303-2.303-5.484-3.728-8.999-3.728
		C8.849,3.274,3.152,8.972,3.152,16c0,7.028,5.697,12.726,12.726,12.726c3.514,0,6.696-1.425,8.999-3.728L22.116,22.238z"/>
		{/if}
		{#if drawLogo1}
		<path in:draw="{{duration: 1000, easing: quintInOut}}" out:fly={{y: 32, duration: 500, easing: quintInOut }} stroke="#FFFFFF" stroke-width="0.1" d="M17.615,17.657c-0.424,0.424-1.01,0.686-1.657,0.686c-1.293,0-2.342-1.048-2.342-2.342
		c0-1.294,1.049-2.343,2.342-2.343c0.647,0,1.233,0.262,1.657,0.686l2.816-2.816c-1.146-1.145-2.727-1.853-4.473-1.853
		c-3.493,0-6.325,2.832-6.325,6.325c0,3.494,2.832,6.325,6.325,6.325c1.747,0,3.328-0.708,4.473-1.853L17.615,17.657z"/>
		
		{/if}
		{#if drawLogo2}
		<path in:draw="{{delay: 1000, duration: 1000, easing: quintInOut}}" out:fly={{y: 32,  duration: 500, easing: quintInOut }} stroke="#FFFFFF" stroke-width="0.1" d="M22.116,22.238c-1.597,1.596-3.802,2.584-6.238,2.584c-4.872,0-8.821-3.95-8.821-8.822
		c0-4.872,3.949-8.821,8.821-8.821c2.436,0,4.641,0.987,6.238,2.583l2.761-2.761c-2.303-2.303-5.484-3.728-8.999-3.728
		C8.849,3.274,3.152,8.972,3.152,16c0,7.028,5.697,12.726,12.726,12.726c3.514,0,6.696-1.425,8.999-3.728L22.116,22.238z"/>
		{/if}
	</svg>